import React from "react";
import "./SkipConfirmDialog.css";
import BeatLoader from "react-spinners/BeatLoader";
import { useState } from "react";

const SkipConfirmDialog = ({
  title,
  imageName,
  description,
  points,
  OnSkipConfirmation,
  onCancel,
}) => {
  const handleYesClick = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    OnSkipConfirmation(false);
  };

  const handleContinueClick = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    OnSkipConfirmation(true);
  };

  return (
    <div className="overlay">
      <div className="confirm-item">
        <h3 style={{ fontSize: "22px" }}>{title}</h3>
        <div className="card-icon">
          <img
            src={`images/onboarding/skipwarning.png`}
            alt={imageName}
            width={100}
            height={100}
          />
        </div>
        <p>you are about to loose</p>
        <div className="point">
          <img src={`images/coin.png`} alt={title} width={24} height={24} />
          <span style={{ verticalAlign: "top", marginLeft: "4px" }}>
            250,000
          </span>
        </div>
        <p>Are you sure?</p>
        <div>
          <button className="confirm-yes-button" onClick={handleYesClick}>
            No
          </button>
        </div>
        <div style={{ marginTop: "10px" }}>
          <a
            className="confirm-continue-button"
            onClick={handleContinueClick}
            href="#"
          >
            Continue
          </a>
        </div>
      </div>
    </div>
  );
};

export default SkipConfirmDialog;
