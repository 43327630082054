import React from "react";
import "./style.css"; // Assuming the CSS is in a separate file

const Button3D = ({
  style = "",
  text = "Start",
  onClick,
  active = false,
  enable = true,
}) => {
  return (
    <button
      className={`button-3d ${active ? "gray" : ""} ${
        enable ? "" : "disabled"
      } ${style}`}
      role="button"
      onClick={onClick}
    >
      <span className="text">{text}</span>
    </button>
  );
};

export default Button3D;
