import React, { useState, useEffect } from "react";

const CountdownButton = ({ timerCount, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(timerCount);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      setIsDisabled(true); // Disable the button when the countdown is active
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer); // Cleanup interval on component unmount
    } else {
      setIsDisabled(false); // Re-enable the button after countdown finishes
      onComplete();
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <button
      className="start-button"
      disabled={isDisabled}
    >
      {timeLeft > 0 ? formatTime(timeLeft) : "Time's up!"}
    </button>
  );
};

export default CountdownButton;
