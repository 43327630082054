import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import "./DailyReward.css";

const rewards = [
  { day: 1, amount: "100" },
  { day: 2, amount: "500" },
  { day: 3, amount: "1K" },
  { day: 4, amount: "5K" },
  { day: 5, amount: "10K" },
  { day: 6, amount: "25K" },
  { day: 7, amount: "50K" },
  { day: 8, amount: "100K" },
  { day: 9, amount: "250K" },
  { day: 10, amount: "500K" },
  { day: 11, amount: "1M" },
  { day: 12, amount: "2M" },
];

const DailyReward = ({ onCancel, timeRemaining, onClaim }) => {
  const [currentDay, setCurrentDay] = useState(0);
  const [canClaimReward, setCanClaimReward] = useState(false);
  const [timeUntilNextReward, setTimeUntilNextReward] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    checkRewardStatus(controller.signal);

    return () => controller.abort(); // Cleanup to prevent memory leaks
  }, []);

  const checkRewardStatus = async (signal) => {
    try {
      const response = await apiService.get("/daily-reward-status", { signal });
      const { canClaim, nextClaimTime, day } = response.data.data;
      setCurrentDay(day);
      setCanClaimReward(canClaim);
      if (!canClaim) {
        setTimeUntilNextReward(calculateTimeUntilNextReward(nextClaimTime));
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error checking reward status:", error);
      }
    }
  };

  const claimReward = async () => {
    try {
      const response = await apiService.post("/claim-daily-reward");
      if (response.data.status) {
        setCanClaimReward(false);
        // setCurrentDay((prevDay) => prevDay + 1);
        setTimeUntilNextReward("24h 0m"); // Assuming reset after 24 hours
        onClaim();
      }
    } catch (error) {
      console.error("Error claiming reward:", error);
    }
  };

  const calculateTimeUntilNextReward = (nextRewardTime) => {
    const now = new Date();
    const nextReward = new Date(nextRewardTime);
    const timeUntil = nextReward - now;
    const hours = Math.floor(timeUntil / (1000 * 60 * 60));
    const minutes = Math.floor((timeUntil % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    if (!canClaimReward && timeUntilNextReward) {
      const interval = setInterval(() => {
        setTimeUntilNextReward((prevTime) =>
          calculateTimeUntilNextReward(prevTime)
        );
      }, 60000); // Update every 1 minute

      return () => clearInterval(interval);
    }
  }, [timeUntilNextReward, canClaimReward]);

  return (
    <div className="overlay">
      <div className="container">
        <div className="reward-close-button" onClick={onCancel}>
          x
        </div>

        <div className="reward-image-container">
          <img
            src="images/tasks/task.png"
            alt="daily reward"
            className="cute-character"
          />
        </div>

        <h2 className="reward-title">Daily Rewards</h2>
        <p className="subtitle">
          Login every 24 hours to collect the daily rewards
        </p>

        <div className="rewards-grid">
          {rewards.map((reward) => (
            <div
              key={reward.day}
              className={`reward ${
                currentDay === reward.day && canClaimReward
                  ? "today"
                  : currentDay >= reward.day
                  ? "active"
                  : "inactive"
              }`}
            >
              <div className="day-text">Day {reward.day}</div>
              <img src="images/coin.png" alt="coin" className="coin-image" />
              <div className="amount-text">{reward.amount}</div>
            </div>
          ))}
        </div>
        {canClaimReward ? (
          <button className="button" onClick={claimReward}>
            Claim
          </button>
        ) : (
          <button className="button" disabled>
            {timeRemaining ? `${timeRemaining}` : `${timeUntilNextReward}`}
          </button>
        )}
      </div>
    </div>
  );
};

export default DailyReward;
